import './App.css';
import Layout from './components/Layout';
import { useRef } from 'react';
import Hero from './components/Hero';
import Divider from './components/Divider';
import Technologies from './components/Technologies';
import Projects from './components/Projects';
import Career from './components/Career';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import VisualizerPage from './pages/Visualizer';
import AboutMe from './components/AboutMe';

function App() {
  const projectsRef = useRef(null);
  const careerRef = useRef(null);
  const aboutRef = useRef(null);

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <Layout projectsRef={projectsRef} careerRef={careerRef}>
            <Hero aboutRef={aboutRef}/>
            <Technologies/>
            <div ref={projectsRef}>
              <Projects />
            </div>
            <div ref={aboutRef}>
              <AboutMe />
            </div>
            <div ref={careerRef}>
            <Career />
          </div>
          </Layout>
        } />
        <Route path="/visualizer" element={
          <Layout projectsRef={projectsRef} careerRef={careerRef}>
            <VisualizerPage />
          </Layout>
        } />
      </Routes>
    </Router>
  );
}

export default App;
