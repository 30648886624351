import React, { useState, useEffect } from 'react';
import { useTransform, useViewportScroll, motion, AnimatePresence } from 'framer-motion';
import { Icon } from '@iconify/react';
import { MagicCard, MagicContainer } from './ui/magic-card';
import { Button } from './ui/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

interface ProjectItemProps {
    contribution: boolean;
    award: boolean;
    name: string;
    images: string[];
    bulletPoints: string[];
    source?: string;
    live?: string;
    proof?: string;
    shortDescription: string;
}

const ProjectItem: React.FC<ProjectItemProps> = ({ contribution, award, name, images, bulletPoints, source, live, proof, shortDescription }) => {
    const { scrollYProgress } = useViewportScroll();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [selectedId, setSelectedId] = useState<string | null>(null);
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    const scale = useTransform(scrollYProgress, isMobile ? [0, 0.2] : [0, 0.3], [0.3, 1]);

    useEffect(() => {
        if (images.length > 1) {
            const interval = setInterval(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
            }, 5000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [images.length]);

    return (
        <>
            <motion.div layoutId={name} onClick={() => setSelectedId(name)} className="w-full z-20">
    <MagicContainer>
        <MagicCard className="flex flex-col items-center justify-center p-8 shadow-2xl cursor-pointer" borderColor="rgba(94 92 230)">
            <motion.div className="w-full h-32 overflow-hidden rounded-lg mb-4">
                <img
                    src={images[0]} // Preview the first image in the images array
                    alt={`${name} preview`}
                    className="object-cover w-full h-full"
                />
            </motion.div>
            <motion.div className="text-4xl font-semibold text-custom-gray-dark-1">
                {name}
            </motion.div>
            <motion.div className="text-sm text-center text-custom-gray-dark-4 mt-2">
                {shortDescription}
            </motion.div>
        </MagicCard>
    </MagicContainer>
</motion.div>


            <AnimatePresence>
                {selectedId && (
                    <motion.div layoutId={selectedId} className="fixed inset-0 z-50 bg-black/70 flex items-center justify-center p-4">
                        <motion.div className="bg-background rounded-xl p-8 overflow-hidden relative flex flex-col w-full max-w-lg">
                            <div className="flex items-center justify-between w-full text-white mb-4">
                                <p>{contribution ? t('projects.contribution') : t('projects.personal')}</p>
                                {award && (
                                    <div className="flex items-center space-x-1">
                                        <Icon icon="mdi:trophy-award" className="text-custom-yellow-dark" />
                                        <p>{t('projects.award')}</p>
                                    </div>
                                )}
                            </div>
                            <div className="relative w-full h-48 mb-4">
                                {images.map((src, imgIndex) => (
                                    <motion.img
                                        key={src}
                                        src={src}
                                        className="absolute rounded-lg transition-opacity duration-500 w-full h-full object-cover"
                                        style={{
                                            opacity: imgIndex === currentImageIndex ? 1 : 0,
                                            x: imgIndex === currentImageIndex ? '0%' : imgIndex < currentImageIndex ? '-100%' : '100%',
                                        }}
                                        transition={{ duration: 0.5 }}
                                    />
                                ))}
                            </div>
                            <motion.div className="flex items-center justify-between w-full mt-4 space-x-2">
                                {proof && (
                                    <Link to={proof}>
                                        <Button>{t('projects.proof')}</Button>
                                    </Link>
                                )}
                                {live ? (
                                    <Link to={live}>
                                        <Button>{t('projects.live')}</Button>
                                    </Link>
                                ) : (
                                    <Button disabled>{t('projects.noLive')}</Button>
                                )}
                                {source ? (
                                    <Link to={source}>
                                        <Button>{t('projects.source')}</Button>
                                    </Link>
                                ) : (
                                    <Button disabled>{t('projects.noSource')}</Button>
                                )}
                            </motion.div>
                            <div className="text-white mt-4 space-y-2">
                                {bulletPoints.map((bulletPoint, bulletIndex) => (
                                    <div key={bulletIndex} className="flex items-start space-x-1">
                                        <p>•</p>
                                        <p>{bulletPoint}</p>
                                    </div>
                                ))}
                            </div>
                            <motion.button
                                className="absolute top-4 right-4 text-white text-2xl"
                                onClick={() => setSelectedId(null)}
                            >
                                &times;
                            </motion.button>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default ProjectItem;
