import React, { useState, ReactNode, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Icon } from '@iconify/react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface LayoutProps {
  children: ReactNode;
  projectsRef: React.RefObject<HTMLDivElement>;
  careerRef: React.RefObject<HTMLDivElement>;
}

const Layout: React.FC<LayoutProps> = ({ children, projectsRef, careerRef }) => {
  const [selectedNav, setSelectedNav] = useState('home');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const dropdownRef = useRef<HTMLUListElement>(null);
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavClick = (navItem: string) => {
    setSelectedNav(navItem);
    
    if (navItem === 'Home') {
      if (window.location.pathname !== '/') {
        navigate('/');
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } else if (navItem === 'Projects' && projectsRef.current) {
      navigate('/');
      projectsRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (navItem === 'About' && careerRef.current) {
      navigate('/');
      careerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
    setIsDropdownOpen(false);
    
    i18n.changeLanguage(language === 'English' ? 'en' : 'de');
  };

  const handleClickOutside = (event: MouseEvent) => {
    const clickedOutside =
      dropdownRef.current && !dropdownRef.current.contains(event.target as Node) &&
      dropdownButtonRef.current && !dropdownButtonRef.current.contains(event.target as Node);
    
    if (clickedOutside) {
      setIsDropdownOpen(false);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const navItemVariants = {
    hidden: { y: -50, opacity: 0 },
    visible: { y: 0, opacity: 1 },
    exit: { y: -50, opacity: 0 },
  };

  const dropdownVariants = {
    hidden: { height: 0, opacity: 0 },
    visible: { height: 'auto', opacity: 1, transition: { duration: 0.3 } },
    exit: { height: 0, opacity: 0, transition: { duration: 0.3 } },
  };

  return (
    <div className="h-screen w-screen bg-background relative">
      <nav className="fixed top-6 left-1/2 transform -translate-x-1/2 flex justify-center z-[999] bg-background border border-border rounded-lg py-1 px-2 w-auto">
  <motion.ul
    initial="hidden"
    animate="visible"
    exit="exit"
    variants={{
      hidden: {},
      visible: { transition: { staggerChildren: 0.1 } },
      exit: {}
    }}
    className="flex space-x-6 text-white font-semibold text-sm md:text-xl lg:text-2xl"
  >
    {[t('layout.nav.home'), t('layout.nav.projects'), t('layout.nav.about'), t('layout.nav.contact')].map((item, index) => (
      <motion.li key={index} variants={navItemVariants}>
        <button
          className={`transition-colors duration-150 ${
            selectedNav === item ? 'text-custom-indigo-light font-bold' : 'hover:text-custom-indigo-light'
          }`}
          onClick={() => handleNavClick(item)}
        >
          {item}
        </button>
      </motion.li>
    ))}
    <motion.li className="relative" variants={navItemVariants}>
      <button
        ref={dropdownButtonRef}
        className="flex items-center text-white font-semibold focus:outline-none hover:text-custom-indigo-light transition-colors duration-150"
        onClick={toggleDropdown}
      >
        {selectedLanguage}
        <motion.div
          animate={isDropdownOpen ? { rotate: 180, y: 5, x: 2 } : {}}
          className="ml-1"
        >
          <Icon icon='material-symbols:keyboard-arrow-down-rounded' className='ml-0 mt-2' />
        </motion.div>
      </button>
      <motion.ul
        ref={dropdownRef}
        variants={dropdownVariants}
        initial="hidden"
        animate={isDropdownOpen ? "visible" : "hidden"}
        exit="exit"
        className="absolute right-0 mt-2 w-40 bg-background border border-custom-indigo-light rounded-xl shadow-lg z-50 p-2 overflow-hidden"
      >
        <li>
          <button
            className="block px-4 py-2 text-left text-white hover:bg-custom-indigo-light w-full rounded-lg"
            onClick={() => handleLanguageChange('English')}
          >
            English
          </button>
        </li>
        <li>
          <button
            className="block px-4 py-2 text-left text-white hover:bg-custom-indigo-light w-full rounded-lg"
            onClick={() => handleLanguageChange('German')}
          >
            German
          </button>
        </li>
      </motion.ul>
    </motion.li>
  </motion.ul>
</nav>


      <div>{children}</div>
    </div>
  );
};

export default Layout;
