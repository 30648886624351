import TechnologyItem from "./TechnologyItem";
import BlurFade from './ui/BlurFade';
import { useTranslation } from "react-i18next";


const Technologies: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className="w-full h-[25rem] md:h-[13.5rem] bg-background flex flex-col items-center justify-center mt-10">
            <BlurFade delay={0.25} inView>
                <h2 className="text-xl md:text-2xl font-semibold text-center mb-4 text-white h-[2rem]">
                    {t('technologies.title')}
                </h2>
            </BlurFade>

            <div className="grid grid-cols-2 grid-rows-4 md:grid-cols-4 md:grid-rows-2 w-full md:w-3/4 h-full gap-2">
                <TechnologyItem technology="figma" />
                <TechnologyItem technology="typescript" />
                <TechnologyItem technology="react" />
                <TechnologyItem technology="tailwind" />
                <TechnologyItem technology="git" />
                <TechnologyItem technology="svelte" />
                <TechnologyItem technology="framer" />
                <TechnologyItem technology="i18n" />
            </div>
        </div>
    );
}

export default Technologies;
