import React from 'react';
import FlickeringGrid from './ui/FlickeringGrid';
import ProjectItem from './ProjectItem';
import BlurFade from './ui/BlurFade';
import { useTranslation } from 'react-i18next';
import { MagicContainer } from './ui/magic-card';

const Projects: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="relative flex flex-col items-center justify-center">
        <BlurFade delay={0.25} inView className='z-20'>
            <div className="relative my-8">
                <h2 className="text-white text-xl md:text-4xl font-bold">{t('projects.title')}</h2>
            </div>
        </BlurFade>

      <div className="bg-gradient-to-b absolute from-background via-transparent to-background h-full w-full z-10" />

      <MagicContainer className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4 z-20 relative w-full max-w-4xl">
        <ProjectItem
          contribution={true}
          shortDescription={t('projects.workwatch.description')}
          award={true}
          proof='https://www.gfos.com/de/presse/auszeichnung-junger-it-talente-preisverleihung-des-gfos-innovationsawards-2024/'
          name={t('projects.workwatch.title')}
          images={["./WorkwatchPreview1.avif", "./WorkwatchPreview2.avif", "./WorkwatchPreview3.avif"]}
          bulletPoints={[
            t('projects.workwatch.dotpoint.1'),
            t('projects.workwatch.dotpoint.2'),
            t('projects.workwatch.dotpoint.3'),
            t('projects.workwatch.dotpoint.4'),

          ]}
        />
        <ProjectItem
          contribution={false}
          shortDescription={t('projects.ruhrpottmetaller.description')}
          award={false}
          live='https://ruhrpottmetaller.com/'
          name='Ruhrpottmetaller'
          images={["./RuhrpottmetallerPreview1.avif", "./RuhrpottmetallerPreview2.avif"]}
          bulletPoints={[
            t('projects.ruhrpottmetaller.dotpoint.1'),
            t('projects.ruhrpottmetaller.dotpoint.2'),
          ]}
        />
        <ProjectItem
          contribution={false}
          shortDescription={t('projects.visualizer.description')}
          award={false}
          live='/visualizer'
          name={t('projects.visualizer.title')}
          images={["./VisualizerPreview.avif"]}
            bulletPoints={[
              t('projects.visualizer.dotpoint.1'),
              t('projects.visualizer.dotpoint.2'),
            ]}
        />
      </MagicContainer>

      <FlickeringGrid
        className="absolute top-0 left-0 w-full h-full z-0"
        squareSize={4}
        gridGap={6}
        color="#FFFFFF"
        maxOpacity={0.05}
        flickerChance={0.1}
      />
    </div>
  );
};

export default Projects;
