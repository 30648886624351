import React, { useState, useEffect} from 'react';
import { Icon } from "@iconify/react";
import { useTransform, useViewportScroll, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "./ui/HoverCard";

interface Technology {
    name: string;
    icon: string;
    bgColor: string;
}

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

const technologies: Record<string, Technology> = {
    figma: {
        name: 'Figma',
        icon: 'logos:figma',
        bgColor: 'bg-custom-gray-dark-4',
    },
    typescript: {
        name: 'TypeScript',
        icon: 'logos:typescript-icon',
        bgColor: 'bg-[#182432]',
    },
    react: {
        name: 'React',
        icon: 'logos:react',
        bgColor: 'bg-[#11282F]',
    },
    tailwind: {
        name: 'Tailwind',
        icon: 'logos:tailwindcss-icon',
        bgColor: 'bg-[#122B37]',
    },
    git: {
        name: 'Git',
        icon: 'logos:git-icon',
        bgColor: 'bg-[#331914]',
    },
    svelte: {
        name: 'Svelte',
        icon: 'logos:svelte-icon',
        bgColor: 'bg-[#811F00]',
    },
    framer: {
        name: 'Framer Motion',
        icon: 'logos:framer',
        bgColor: 'bg-custom-indigo-dark',
    },
    i18n: {
        name: 'i18n',
        icon: 'simple-icons:i18next',
        bgColor: 'bg-[#C4DFE3]',
    },
};

interface TechnologyItemProps {
    technology: keyof typeof technologies;
}

const TechnologyItem: React.FC<TechnologyItemProps> = ({ technology }) => {
    const { t } = useTranslation();
    const tech = technologies[technology];
    const isMobile = useIsMobile();
    const { scrollYProgress } = useViewportScroll();
    const scale = useTransform(scrollYProgress, isMobile ? [0, 0.1] : [0, 0.15], [0.2, 1]);

    return (
        <HoverCard>
            <HoverCardTrigger>
                <motion.div className="w-full h-full bg-custom-gray-dark-6 hover:bg-custom-gray-dark-5 border border-transparent hover:border-custom-gray-dark-3 rounded-xl p-2 flex flex-row space-x-4 transition-colors duration-150"
                    style={{ scale }}>
                    <div className={`h-full w-auto aspect-square rounded-lg ${tech.bgColor} flex items-center justify-center group`}>
                        <Icon icon={tech.icon} className='text-4xl group-hover:scale-105 transition-all duration-150' />
                    </div>
                    <div className="flex flex-col items-left justify-center">
                        <p className="text-white text-sm md:text-md">{tech.name}</p>
                        <p className="text-custom-gray-dark-1 text-sm">{t(`technologies.${technology}.shortDesc`)}</p>
                    </div>
                </motion.div>
            </HoverCardTrigger>
            <HoverCardContent>
                {t(`technologies.${technology}.longDesc`)}
            </HoverCardContent>
        </HoverCard>
    );
};

export default TechnologyItem;
