import React from 'react';
import BlurFade from './ui/BlurFade';
import { useTranslation } from 'react-i18next';

const AboutMe: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="relative flex flex-col items-center justify-center mt-20 my-8">
      <BlurFade delay={0.25} inView className="z-10">
        <div className="relative">
          <h2 className="text-white text-xl md:text-4xl font-bold">{t('aboutMe.title')}</h2>
        </div>
      </BlurFade>

      <div className="p-4 text-center text-white z-10 relative w-full max-w-2xl">
        <p className="text-base md:text-lg">
          {t('aboutMe.text')}
        </p>
      </div>
    </div>
  );
};

export default AboutMe;
