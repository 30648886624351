import React, { useState, useEffect, useRef } from 'react';
import { Icon } from "@iconify/react";
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import BlurFade from './ui/BlurFade';
import AnimatedShinyText from './ui/animated-shiny-text';
import Particles from './ui/particles';

interface IlluminatedBoxes {
    [key: number]: string;
}

interface HeroProps {
    aboutRef: React.RefObject<HTMLDivElement>;
}

const Hero: React.FC<HeroProps> = ({ aboutRef }) => {
    const { t, i18n } = useTranslation();
    const [illuminatedBoxes, setIlluminatedBoxes] = useState<IlluminatedBoxes>({});
    const [isMobile, setIsMobile] = useState(false);
    const timeoutsRef = useRef<Map<number, NodeJS.Timeout>>(new Map());

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const handleAboutClick = () => {
        if (aboutRef.current) {
            aboutRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleBoxClick = (index: number) => {
        console.log(`Animation started for box at index: ${index}`);
        
        const newIlluminatedBoxes = { ...illuminatedBoxes };
        const numCols = isMobile ? 12 : 48;
        const numRows = isMobile ? 20 : 28;
        const effectDuration = 1000;

        const isValidIndex = (idx: number) => idx >= 0 && idx < numCols * numRows;

        const setIllumination = (idx: number, className: string) => {
            if (isValidIndex(idx)) newIlluminatedBoxes[idx] = className;
        };

        setIllumination(index, 'illuminate-center');
        [index - numCols, index + numCols, index - 1, index + 1].forEach(adjIndex =>
            setIllumination(adjIndex, 'illuminate-adjacent')
        );
        [index - numCols - 1, index - numCols + 1, index + numCols - 1, index + numCols + 1].forEach(diagIndex =>
            setIllumination(diagIndex, 'illuminate-diagonal')
        );

        setIlluminatedBoxes(newIlluminatedBoxes);

        if (timeoutsRef.current.has(index)) {
            clearTimeout(timeoutsRef.current.get(index));
            timeoutsRef.current.delete(index);
        }

        const timeout = setTimeout(() => {
            console.log(`Animation ended for box at index: ${index}`);
            
            setIlluminatedBoxes((prev) => {
                const updatedIlluminatedBoxes = { ...prev };
                delete updatedIlluminatedBoxes[index];
                [index - numCols, index + numCols, index - 1, index + 1, index - numCols - 1, index - numCols + 1, index + numCols - 1, index + numCols + 1].forEach(idx => {
                    delete updatedIlluminatedBoxes[idx];
                });
                return updatedIlluminatedBoxes;
            });

            timeoutsRef.current.delete(index);
        }, effectDuration);

        timeoutsRef.current.set(index, timeout);
    };

    useEffect(() => {
        return () => {
            timeoutsRef.current.forEach((timeout) => clearTimeout(timeout));
            timeoutsRef.current.clear();
        };
    }, []);

    const resumeFile = i18n.language === 'en' ? '/resume-en.pdf' : '/resume-de.pdf';

    return (
        <div id="hero" role="presentation" className="relative h-screen w-full flex justify-center items-center overflow-hidden">
            <Particles
                className="absolute w-full h-full inset-0 z-10 pointer-events-none"
                quantity={50}
                ease={80}
                color={'#ffffff'}
                refresh
            />
            <div className="grid grid-cols-12 grid-rows-20 md:grid-cols-48 md:grid-rows-28 absolute w-[120%] h-[110%] -skew-x-6">
                {Array(isMobile ? 240 : 1344).fill(null).map((_, index) => (
                    <div
                        key={index}
                        className={`w-full h-full border border-white/5 bg-background md:hover:bg-custom-indigo-dark/30 ${illuminatedBoxes[index] || ''}`}
                        onClick={() => handleBoxClick(index)}
                    />
                ))}
            </div>
            <div className="grid-gradient absolute inset-0"></div>
            
            <BlurFade delay={0.25} inView className='z-10 pointer-events-none'>
                <div className="text-white mb-[10rem] flex flex-col items-center md:items-start md:mr-[15rem]">
                    <div className="flex items-center mb-2 space-x-2">
                        <motion.div
                        className='pointer-events-auto'
                        whileHover={{ scale: 1.2, rotate: 360 }}
                        whileTap={{
                          scale: 0.8,
                          rotate: -360,
                          borderRadius: "100%"}}>
                        <Icon icon='circle-flags:de'/>
                        </motion.div>
                        <span className="text-sm text-custom-gray-dark-1 font-semibold">Based in Germany</span>
                    </div>
                    
                    <h1 className="font-bold text-5xl sm:text-6xl md:text-7xl text-center md:text-left">Miguel Cadeddu</h1>
                    <AnimatedShinyText>
                        <p className="text-2xl sm:text-3xl md:text-4xl font-semibold mt-2 text-center md:text-left">Web Developer</p>
                    </AnimatedShinyText>
                    
                    <button className="w-[80%] relative text-xl sm:text-2xl text-white mt-4 flex flex-row items-center justify-center md:justify-start font-semibold"
                        onClick={handleAboutClick}>
                        <span className="inline-flex items-center hover:scale-105 transition-all duration-100 relative pointer-events-auto">
                            <span className="underline decoration-[4px] dark:decoration-custom-indigo-dark decoration-custom-indigo-light underline-offset-4 glowing-underline">{t('hero.learnMoreAboutMe')}</span>
                            <Icon icon={'material-symbols:arrow-right-alt-rounded'} className="ml-2" />
                        </span>
                    </button>

                    <div className="flex justify-center md:justify-start items-center w-full mt-6 space-x-6 text-white pointer-events-auto">
                        <a href="https://github.com/Azur1337" className="hover:scale-110 dark:hover:text-custom-indigo-dark hover:text-custom-indigo-light transition-all duration-150">
                            <Icon icon="mdi:github" className="text-3xl" />
                        </a>
                        <a href="https://www.figma.com/@azur1337" className="hover:scale-110 dark:hover:text-custom-indigo-dark hover:text-custom-indigo-light transition-all duration-150">
                            <Icon icon="solar:figma-bold-duotone" className="text-3xl" />
                        </a>
                        <a href="https://www.linkedin.com/in/azurdev/" className="hover:scale-110 dark:hover:text-custom-indigo-dark hover:text-custom-indigo-light transition-all duration-150">
                            <Icon icon="mdi:linkedin" className="text-3xl" />
                        </a>
                        <a href={resumeFile} download className="dark:hover:text-custom-indigo-dark hover:text-custom-indigo-light transition-all duration-150 flex flex-row items-center border-2 border-custom-indigo-dark rounded-full p-2 justify-center">
                            <Icon icon="mdi:file-download" className="text-3xl" />
                            {t('hero.resume')}
                        </a>
                    </div>
                </div>
            </BlurFade>
        </div>
    );
};

export default Hero;
