import { useEffect, useRef, useState } from 'react';
import { Slider } from './ui/Slider';
import { ny } from '../lib/utils';
import { Icon } from '@iconify/react';
import { Button } from './ui/Button';
import { useNavigate } from 'react-router-dom';

const createShader = (gl, type, source) => {
    const shader = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.error('Shader compile failed:', gl.getShaderInfoLog(shader));
        gl.deleteShader(shader);
        return null;
    }
    return shader;
};

const createProgram = (gl, vertexShader, fragmentShader) => {
    const program = gl.createProgram();
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);
    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
        console.error('Program link failed:', gl.getProgramInfoLog(program));
        gl.deleteProgram(program);
        return null;
    }
    return program;
};

const Visualizer = () => {
    const canvasRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(50);
    const navigate = useNavigate();
    const audioCtxRef = useRef(null);
    const audioRef = useRef(null);
    const analyserRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const gl = canvas.getContext("webgl", { alpha: true });

        if (!gl) {
            console.error('WebGL not supported');
            return;
        }

        const vertexShaderSource = `
            attribute vec2 a_position;
            void main() {
                gl_Position = vec4(a_position, 0.0, 1.0);
            }
        `;

        const fragmentShaderSource = `
            precision mediump float;
            uniform vec2 u_resolution;
            uniform float u_time;
            uniform float u_audioData;

            vec2 hash(vec2 p) {
                p = vec2(dot(p, vec2(127.1, 311.7)), dot(p, vec2(269.5, 183.3)));
                return -1.0 + 2.0 * fract(sin(p) * 43758.5453123);
            }

            float noise(vec2 p) {
                vec2 i = floor(p);
                vec2 f = fract(p);
                vec2 u = f * f * (3.0 - 2.0 * f);
                return mix(mix(dot(hash(i + vec2(0.0, 0.0)), f - vec2(0.0, 0.0)),
                               dot(hash(i + vec2(1.0, 0.0)), f - vec2(1.0, 0.0)), u.x),
                           mix(dot(hash(i + vec2(0.0, 1.0)), f - vec2(0.0, 1.0)),
                               dot(hash(i + vec2(1.0, 1.0)), f - vec2(1.0, 1.0)), u.x), u.y);
            }

            vec3 getGradientColor(float t) {
                vec3 pink = vec3(211.0/255.0, 37.0/255.0, 178.0/255.0);
                vec3 yellow = vec3(203.0/255.0, 168.0/255.0, 24.0/255.0);
                float cycle = fract(t);
                return (cycle < 0.5) ? mix(pink, yellow, cycle * 2.0) : mix(yellow, pink, (cycle - 0.5) * 2.0);
            }

            void main() {
                vec2 uv = gl_FragCoord.xy / u_resolution;
                uv = uv * 2.0 - 1.0;
                uv.x *= u_resolution.x / u_resolution.y;

                float radius = 0.5 + u_audioData * 0.5;
                float edgeThickness = 0.05;
                float dist = length(uv) - radius;

                float n = noise(uv * 4.0 + vec2(u_time * 0.5, u_time * 0.5)) * 0.1;
                n += noise(uv * 8.0 + vec2(u_time * 0.3, -u_time * 0.3)) * 0.05;

                float edge = smoothstep(edgeThickness, 0.0, dist + n);

                float gradientSpeed = 0.02;
                float gradientPosition = fract(uv.x * 0.25 - u_time * gradientSpeed);
                vec3 color = getGradientColor(gradientPosition);

                color *= edge;

                gl_FragColor = vec4(color, edge);
            }
        `;

        const vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
        const fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource);
        const program = createProgram(gl, vertexShader, fragmentShader);

        if (!program) return;

        const positionBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
            -1, -1, 1, -1, -1, 1,
            -1, 1, 1, -1, 1, 1,
        ]), gl.STATIC_DRAW);

        const positionLocation = gl.getAttribLocation(program, "a_position");
        gl.enableVertexAttribArray(positionLocation);
        gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);

        gl.useProgram(program);

        const resolutionLocation = gl.getUniformLocation(program, "u_resolution");
        const timeLocation = gl.getUniformLocation(program, "u_time");
        const audioDataLocation = gl.getUniformLocation(program, "u_audioData");

        const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        audioCtxRef.current = audioCtx;
        const analyser = audioCtx.createAnalyser();
        analyser.fftSize = 256;
        analyserRef.current = analyser;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        
        const audio = new Audio('./music.mp3');
        audio.crossOrigin = "anonymous";
        audioRef.current = audio;
        const source = audioCtx.createMediaElementSource(audio);
        source.connect(analyser);
        source.connect(audioCtx.destination);

        const render = (time) => {
            time *= 0.001;

            gl.canvas.width = gl.canvas.clientWidth;
            gl.canvas.height = gl.canvas.clientHeight;
            gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

            gl.uniform2f(resolutionLocation, gl.canvas.width, gl.canvas.height);
            gl.uniform1f(timeLocation, time);

            analyser.getByteFrequencyData(dataArray);
            const average = dataArray.reduce((sum, value) => sum + value, 0) / bufferLength;
            const audioData = average / 256.0;

            gl.uniform1f(audioDataLocation, audioData);

            gl.clear(gl.COLOR_BUFFER_BIT);
            gl.drawArrays(gl.TRIANGLES, 0, 6);

            requestAnimationFrame(render);
        };

        requestAnimationFrame(render);

        return () => {
            audio.pause();
            audio.src = '';
        };
    }, []);

    const handlePlay = () => {
        const audioCtx = audioCtxRef.current;
        if (audioCtx.state === 'suspended') {
            audioCtx.resume();
        }

        setIsPlaying(true);
        const audio = audioRef.current;
        if (audio) {
            audio.play();
            audio.volume = volume / 100;
        }
    };

    const handlePause = () => {
        setIsPlaying(false);
        const audio = audioRef.current;
        if (audio) {
            audio.pause();
        }
    };

    const handleVolumeChange = (newValues) => {
        const newVolume = newValues[0];
        setVolume(newVolume);
        const audio = audioRef.current;
        if (audio) {
            audio.volume = newVolume / 100;
        }
    };
    

    return (
        <div className='w-screen h-screen'>
            <canvas ref={canvasRef} className="w-full h-full" />
            <div className='fixed w-full flex flex-row justify-between top-5 px-5'>
                <Button onClick={() => navigate('/')} variant={'link'} className='hover:scale-125 transition-all duration-150'>
                    <Icon icon='material-symbols:arrow-back' className={ny('text-white text-xl')} />
                </Button>
            </div>
            <div className='fixed w-full flex flex-row justify-center bottom-20'>
                {isPlaying ? (
                    <Button onClick={handlePause} variant={'link'} className='hover:scale-125 transition-all duration-150'>
                        <Icon icon='material-symbols:pause-rounded' className={ny('text-white text-xl')} />
                    </Button>
                ) : (
                    <Button onClick={handlePlay} variant={'link'} className='hover:scale-125 transition-all duration-150'>
                        <Icon icon='material-symbols:play-arrow-rounded' className={ny('text-white text-xl')} />
                    </Button>
                )}
                <Slider
                    value={[volume]}
                    defaultValue={[50]}
                    max={100}
                    step={1}
                    onValueChange={handleVolumeChange}
                    className={ny('w-1/2 md:w-1/4')}
                />
            </div>
        </div>

    );
};

export default Visualizer;
