import type { CSSProperties, FC, ReactNode } from 'react'
import { ny } from '../../lib/utils'

interface AnimatedShinyTextProps {
   children: ReactNode
   className?: string
   shimmerWidth?: number
}

const AnimatedShinyText: FC<AnimatedShinyTextProps> = ({
   children,
   className,
   shimmerWidth = 100,
}) => {
   return (
      <p
         style={
            {
               '--shimmer-width': `${shimmerWidth}px`,
            } as CSSProperties
         }
         className={ny(
            'max-w-md text-white/50 dark:text-white/50 ',


            // Shimmer effect
            'animate-shimmer bg-clip-text bg-no-repeat [background-position:0_0] [background-size:var(--shimmer-width)_100%] [transition:background-position_1s_cubic-bezier(.6,.6,0,1)_infinite]',

            // Shimmer gradient
            'bg-gradient-to-r from-transparent via-50% to-transparent  via-custom-indigo-dark',

            className,
         )}
      >
         {children}
      </p>
   )
}

export default AnimatedShinyText;
