import Visualizer from '../components/WebGLCanvas';

const VisualizerPage = () => {
  return (
    <div className="w-screen h-screen overflow-hidden">
      <Visualizer />
    </div>
  );
};

export default VisualizerPage;
