import DotPattern from "./ui/dot-pattern";
import { ny } from '../lib/utils'
import { useTranslation } from "react-i18next";
import {
    Timeline,
    TimelineContent,
    TimelineDot,
    TimelineHeading,
    TimelineItem,
    TimelineLine,
 } from './ui/Timeline'

const Career: React.FC = () => {
    const { t } = useTranslation();
    return (
    <div className="relative w-screen h-auto flex flex-col items-center justify-center mb-12">
        <h1 className="text-3xl md:text-4xl font-bold mb-6 z-10 text-white">
            My Career
        </h1>
        

                <Timeline positions={'center'} className="w-full md:w-3/5">
                    <TimelineItem status="done">
                        <TimelineHeading side={'left'}>
                            {t('timeline.hobby.title.first')}
                        </TimelineHeading>
                        <TimelineDot status="done" />
                        <TimelineLine done />
                        <TimelineContent side="left">
                            {t('timeline.hobby.text.first')}
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem status="done">
                        <TimelineHeading side={'right'}>
                            {t('timeline.hobby.title.second')}
                        </TimelineHeading>
                        <TimelineDot status="done" />
                        <TimelineLine done />
                        <TimelineContent side="right">
                            {t('timeline.hobby.text.second')}
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem status="done">
                        <TimelineHeading side={'left'}>
                            {t('timeline.hobby.title.third')}
                        </TimelineHeading>
                        <TimelineDot status="done" />
                        <TimelineLine done />
                        <TimelineContent side="left">
                            {t('timeline.hobby.text.third')}
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem status="done">
                        <TimelineHeading side={'right'}>
                            {t('timeline.hobby.title.fourth')}
                        </TimelineHeading>
                        <TimelineDot status="done" />
                        <TimelineLine done />
                        <TimelineContent side="right">
                            {t('timeline.hobby.text.fourth')}
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem status="done">
                        <TimelineHeading side={'left'}>
                            {t('timeline.hobby.title.fifth')}
                        </TimelineHeading>
                        <TimelineDot status="done" />
                        <TimelineLine done />
                        <TimelineContent side="left">
                            {t('timeline.hobby.text.fifth')}
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem status="default">
                        <TimelineHeading side={'right'}>
                            {t('timeline.hobby.title.sixth')}
                        </TimelineHeading>
                        <TimelineDot status="current" />
                        <TimelineContent side="right">
                            {t('timeline.hobby.text.sixth')}
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>

        <DotPattern
            className={ny(
               '[mask-image:radial-gradient(400px_circle_at_center,white,transparent)]',
            )}
         />
    </div>
    );
};

export default Career;
